
 const param = ['Très faible','Faible','Modéré', 'Forte']

 /**
  * format
  * @param {string} data 
  * @param {string} type 
  */
 export const format = (data, type) => {
     
        switch(type){
            case 'rain':
                if(data<.1){return param[0]}
                else if(data >= .1 && data < 2.5){ return param[1]} 
                else if(data >= 2.5 && data < 7.5){return param[2]} 
                else if(data >= 7.5){ return param[3]} 
                break;
            case 'bruine':
                if(data<.1){return param[0]}
                else if(data >= .1 || data < .25){ return param[1]} 
                else if(data >= .25 || data < .5){return param[2]} 
                else if(data >= .5){ return param[3]} 
                break;
            case 'snow_w':
                if(data<.1){return param[0]}
                else if(data >= .1 && data < 2.5){ return param[1]} 
                else if(data >= 2.5 && data < 7.5){return param[2]} 
                else if(data >= 7.5){ return param[3]} 
                break;
            case 'snow_h':
                if(data < 25){ return param[1]} 
                else if(data >= 25 && data < 75){return param[2]} 
                else if(data >= 75){ return param[3]} 
                break;
        }

    }

    export default { format };
