<template>
  <div class="responsive-container">
    <div class="timeline-container">
      <div class="timestamps">
        <div class="timeline">
          <div class="timeline-bar" :style="`width:${getBarWidth()}%`"></div>
          <div
            class="timestamp-ghost"
            v-for="(timestamp, i) in timestamps"
            :class="timestamp < currentDay ? 'day-visible' : ''"
            :style="`width:${100 / timestamps.length}%`"
            :key="i"
          >
            <div class="tick" :style="tickAtModulo(timestamp) == 1 ? 'top:0px;':''" v-if="tickAtModulo(timestamp)">|</div>
            <div
              @mouseover="hover = timestamp"
              @mouseleaver="hover = null"
              @click="selected = timestamp"
              :class="selected == timestamp ? 'current-selected' : ''"
              class="pointer"
            ></div>
            <span class="timestamp-tooltip" v-if="hover == timestamp">
              {{ timestampFormat(timestamp) }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="day"
        :style="`width:${getDayWidth(day)}%`"
        v-for="(day, e) in GroupByDay(timestamps)"
        :key="`day${e}`"
      >
        {{ day }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("fr");
export default {
  props: ["timestamps"],
  data: () => ({
    hover: null,
    selected: new Date(),
  }),
  computed: {
    currentDay() {
      return moment().unix() * 1000;
    },
    dayEntireFormat() {
      return moment(this.selected).format("DD/MM HH:mm");
    },
  },
  methods: {
    timestampFormat(timestamp) {
      return moment(timestamp).format("DD MMM HH:mm");
    },
    dayFormat(timestamp) {
      return moment(timestamp).format("ddd DD MMM");
    },
    getBarWidth() {
      if (!this.selected) return 0;

      let index = this.timestamps.findIndex((map) => map == this.selected);
      return (index / this.timestamps.length) * 100 + 1;
    },
    getDayWidth(day) {
      let times = this.timestamps.filter(
        (map) => moment(map).format("ddd DD MMM") == day
      );
      return (times.length / this.timestamps.length) * 100;
    },
    tickAtModulo(timestamp)
    {
      let hh = moment(timestamp).format('HH')
      if(hh == '00' || hh == '06' || hh == '12' || hh == '18')
      { 
        if(parseInt(hh) == 12) return 1
        else return 2
      }else{
        return false
      }
    },
    GroupByDay(timestamps) {
      let days = [];
      let previousDay = null;
      timestamps.forEach((element) => {
        let day = moment(element).format("ddd DD MMM");
        if (day != previousDay) {
          days.push(day);
          previousDay = day;
        }
      });

      return days;
    },
  },
  mounted() {
    this.selected = this.timestamps[0];
    document.addEventListener('keydown', (event) => {
      let idx = this.timestamps.findIndex(map=>map == this.selected);
    if(event.keyCode == 37) {
      if(this.timestamps[idx-1])
      {
        this.selected = this.timestamps[idx-1]
      }else{
        this.selected = this.timestamps[this.timestamps.length - 1]
      }
    }
    else if(event.keyCode == 39) {
      if(this.timestamps[idx+1])
      {
        this.selected = this.timestamps[idx+1]
      }else{
        this.selected = this.timestamps[0]
      }
    }
});
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
  },
};
</script>

<style>
.timeline-container {
  width: 100%;
  white-space: nowrap;
}
.timestamps {
  display: block;
  width: 100%;
  white-space: nowrap;
}

.timeline {
  width: 100%;
  position: relative;
  height: 16px;
  background-color: rgb(216, 216, 216);
  border-radius: 10px;
  line-height: 0px;
}

.timeline-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  background-color: rgb(255, 178, 44);
  border-radius: 10px;
}

.timestamp-ghost {
  display: inline-block;
  white-space: nowrap;
  position: relative;
}

.pointer {
  height: 16px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  border-radius: 20px;
}

.pointer.current-selected {
  background-color: rgb(255, 178, 44);
}

.day-visible {
  background-color: rgb(211, 255, 253, 0.3);
}

.tick {
  position:absolute;
  top:6px;
  left:40%;
  pointer-events: none;
  color:rgba(0,0,0,.2)
}

.timestamp-tooltip {
  position: absolute;
  z-index: 2;
  line-height: 1em;
  background-color: rgb(255, 199, 78);
  border-radius: 10px;
  color: white;
  padding: 2px 5px 2px 5px;
  top: -2em;
  left: -3em;
}

.day {
  display: inline-block;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  overflow: hidden;
  text-align:center;
}

.pointer:hover {
  border-radius: 20px;
  background-color: rgb(62, 62, 62);
}

@media screen and (max-width: 1280px) {
  .responsive-container {
    overflow: auto;
    padding-top: 2em;
  }
  .timeline-container {
    width: 1000px;
  }
}
</style>