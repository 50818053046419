<template>
 <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      v-if="false"
      app
      class="pt-4"
      color="dark lighten-3"
      mini-variant
    >
      <v-avatar
        :color="`grey darken-1`"
        :size="36"
        class="d-block text-center mx-auto mb-9"
      ></v-avatar>
    </v-navigation-drawer>

    <v-main>
   <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">

.leaflet-container {
    z-index: 1;
}

.leaflet-bottom.leaflet-left {
    width: 100%;
}

.leaflet-control {
    width: 100%;
    padding: 0px!important;
    margin: 0px!important;
}
</style>
