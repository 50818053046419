import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_URI;
axios.defaults.baseURL = 'https://sigapi.previmeteo.net';

const getData = e => e.data;

const api = {
    login:(data)=>
        axios.post(`/auth/local`, data).then(getData),
    categories: {
        get: () =>
            axios.get(`/categories`).then(getData)
    }
}

export default api;