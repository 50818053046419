<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row>
      <v-col cols="8" class="pa-0 ma-0">
        <v-row>
          <v-col cols="12" class="pb-0 mb-0">
            <LMap
              :options="{ zoomControl: false, keyboard: false }"
              :center="[47, 2]"
              :zoom="5"
              @update:zoom="updateZoom"
              ref="map"
              style="height: 82vh"
            >
              <LTileLayer
                url="https://api.mapbox.com/styles/v1/pmcom/ck6m9eny40xsn1imy756u9tyw/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA"
              />
              <LTileLayer
                v-if="roadcondTiles"
                url="https://roadtiles.previmeteo.net/{z}/{x}/{y}/generate?key=3d18fd31-2494-413a-b25e-b80deafdb628"
              />

              <LControl position="topright" v-if="travel">
                <v-card class="ma-6" v-if="travel.routes[0].sections[0]">
                  <v-card-text>
                    <h2 :class="travel_conds.find(map=>map.roadcond > 1) ? 'orange--text':''"> Temps de trajet : {{moment(travel.routes[0].sections[0].arrival.time).diff(travel.routes[0].sections[0].departure.time,'hours')}}h </h2>
                    <i v-if="travel_conds.find(map=>map.roadcond > 1)">Ce trajet comporte des conditions de chaussée défavorable</i>
                  </v-card-text>
                </v-card>
              </LControl>

              <template v-for="(item, a) in items">
                <div :key="`a${a}`">
                  <template v-for="(layer, c) in item.tilesets">
                    <LTileLayer
                      v-if="layer.active"
                      :key="`c${c}`"
                      :url="layer.url"
                      :options="{
                        ts: moment().startOf('hours').unix(),
                        ...layer.options,
                      }"
                    />
                  </template>
                </div>
              </template>

              <LTileLayer
                v-if="paths.length > 0 && paths[findCursor]"
                :opacity="rvOpacity"
                :url="`https://tilecache.rainviewer.com${paths[findCursor]}/256/{z}/{x}/{y}/2/1_1.png`"
              />
              <LControl position="topleft" class="pa-6">
                <h1>Conditions de Circulations</h1>
                <i>Demo v0.54</i>
                <legend-radar v-if="roadcondTiles" />
                <legend-roadcond v-if="series.length > 0" />
              </LControl>
              <LMarker
                v-for="(travel, c) in travel_conds"
                :key="`tc${c}`"
                :lat-lng="[travel.pos[1], travel.pos[0]]"
              >
                <LIcon :iconAnchor="[15, 15]">
                  <v-btn
                    :style="`background-color:rgba(${roadCondToColor(
                      travel.roadcond
                    )}, .8)!important;`"
                    elevation="0"
                    fab
                    x-small
                    >{{ roadCondToTitle(travel.roadcond) }}</v-btn
                  >
                </LIcon>
              </LMarker>
              <LFeatureGroup v-if="webcamsLayer">
                <LMarker
                  v-for="(camera, i) in cameras"
                  :key="`cameras${i}`"
                  :lat-lng="[
                    camera.location.latitude,
                    camera.location.longitude,
                  ]"
                >
                  <LIcon>
                    <v-icon>mdi-camera</v-icon>
                  </LIcon>
                  <LPopup>
                    <v-card style="width: 30vw">
                      <v-card-title>{{
                        moment(camera.image.update * 1000).format(
                          "DD/MM/YYYY HH:mm"
                        )
                      }}</v-card-title>
                      <v-img :src="camera.image.current.preview" />
                    </v-card>
                  </LPopup>
                </LMarker>
              </LFeatureGroup>

              <template v-for="(item, i) in items">
                <div :key="`i${i}`">
                  <template v-for="(layer, z) in item.wms">
                    <LWMSTileLayer
                      v-if="layer.active"
                      :key="`z${z}`"
                      :base-url="`${layer.url}&timestamp=${currentTimestamp}`"
                      :layers="layer.layers"
                      :format="layer.imagetype"
                      :transparent="layer.transparent"
                      :options="layer.options"
                    />
                  </template>
                </div>
              </template>
              <!-- 
      <LGeoJson
        v-if="roadsLayer"
        :options="optionsGeojson"
        :key="`b${b}`"
        :geojson="roadsEvents"
      /> -->

              <LCanvasMarker
                v-if="markers.length > 0 && roadsLayer"
                :markers="markers"
              />

              <!-- <LControl position="bottomleft" class="pa-6" v-show="polylines.length > 0"> -->

              <!-- </LControl> -->

              <l-feature-group ref="circuits">
                <template v-for="(polyline, i) in polylines">
                  <LPolyline
                    :key="i"
                    :lat-lngs="polyline.lines"
                    :color="polyline.color"
                  ></LPolyline>
                </template>
              </l-feature-group>
            </LMap>
          </v-col>
          <v-col class="mt-0">
            <v-card>
              <v-card-text>
                <!-- <highcharts
                style="height:200px"
                  :constructor-type="'stockChart'"
                  :options="chartOptions"
                ></highcharts> -->
                <v-simple-table dense>
                  <thead>
                    <tr>
                      <th
                        class="text-center"
                        v-for="(rc, f) in travel_conds"
                        :key="`rcf${f}`"
                      >
                        {{ moment(rc.time * 1000).format("DD/MM HH:MM") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        class="text-center"
                        v-for="(rc, t) in travel_conds"
                        :key="`rct${t}`"
                      >
                        <v-btn
                          :style="`background-color:rgba(${roadCondToColor(
                            rc.roadcond
                          )}, .8)!important;`"
                          elevation="0"
                          fab
                          x-small
                          >{{ roadCondToTitle(rc.roadcond) }}</v-btn
                        >
                      </td>
                    </tr>
                    <tr>

                      <td
                        class="text-center"
                        v-for="(rc, r) in travel_conds"
                        :key="`rctr${r}`"
                      >
                      <template v-if="rc.rain">
                      {{format(rc.rain, 'rain')}}
                      </template>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card elevation="0">
          <v-card-text style="max-height: 100vh; overflow: scroll">
            <v-slider
              label="Opacité radar de précipitation"
              :min="0"
              :max="1"
              :step="0.1"
              v-model="rvOpacity"
            />

            <v-autocomplete
              v-model="from"
              :items="itemsGeocoder"
              :search-input.sync="geocodersearch"
              color="white"
              hide-no-data
              hide-selected
              elevation="0"
              clearable
              hide-details
              item-text="place_name"
              item-value="title"
              label="D'où partez-vous ?"
              return-object
            />
            <v-autocomplete
              v-model="to"
              :items="itemsGeocodertwo"
              :search-input.sync="geocodersearchtwo"
              elevation="0"
              color="white"
              hide-no-data
              hide-selected
              clearable
              hide-details
              item-text="place_name"
              item-value="title"
              label="Où allez vous ?"
              return-object
            />

            <v-text-field
              type="datetime-local"
              label="Quand?"
              :max="maxdt"
              :min="mindt"
              v-model="selectedDate"
            />

            <v-btn elevation="0" :disabled="!this.from || !this.to" @click="navigateTo" color="success">Go</v-btn>

            <v-list
              v-for="item in items"
              :key="item.title"
              v-model="item.active"
              :prepend-icon="item.action"
              no-action
            >
              <!-- <v-list-group
          
        > -->
              <!-- <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template> -->

              <v-list-item v-for="child in item.datasets" :key="child.title">
                <v-list-item-avatar
                  ><v-checkbox v-model="child.active"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="child in item.tilesets" :key="child.title">
                <v-list-item-avatar
                  ><v-checkbox v-model="child.active"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="child in item.wms" :key="child.title">
                <v-list-item-avatar
                  ><v-checkbox v-model="child.active"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar
                  ><v-checkbox v-model="roadcondTiles"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    >Précip. tmps réel sur la route</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-avatar
                  ><v-checkbox v-model="webcamsLayer"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Cameras</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :disabled="loading">
                <v-list-item-avatar
                  ><v-checkbox v-model="roadsLayer"></v-checkbox
                ></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Évènements de routes</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- </v-list-group> -->
            </v-list>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-btn color="error" @click="logout()">Deconnexion</v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar position="bottom left" v-model="loading"
      ><v-progress-circular size="25" indeterminate /> Chargement des données de
      routes en cours...</v-snackbar
    >
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import api from "@/api";
import Highcharts from "highcharts";
import H from "@/components/utils/heredecode";

import stockInit from "highcharts/modules/stock";

import {format} from '@/components/utils/nfp';

stockInit(Highcharts);
import {
  LMap,
  LTileLayer,
  LControl,
  LWMSTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LGeoJson,
  LPolyline,
  LFeatureGroup
} from "vue2-leaflet";
import VueHorizontalTimelineSlider from "../components/VueHorizontalTimelineSlider.vue";
import moment from "moment-timezone";
import LegendRadar from "../components/LegendRadar.vue";
import LCanvasMarker from "@/components/LCanvasMarker";

import L from "leaflet";
import LegendRoadcond from "../components/LegendRoadcond.vue";
moment.tz.setDefault('UTC')
export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    VueHorizontalTimelineSlider,
    LWMSTileLayer,
    LegendRadar,
    LMarker,
    LPopup,
    LGeoJson,
    LFeatureGroup,
    LPolyline,
    LegendRoadcond,
    LIcon,
    LCanvasMarker,
  },
  computed: {
    chartOptions() {
      let series = [];
      if (this.series.length != 0) {
        series = this.series;
        console.log("go series", series);
      }
      return {
        xAxis: {
          type: "datetime",
          labels: {
            formatter: (e) => {
              if (new Date(e.value).getHours() === 0) {
                return moment(e.value).format("D MMM");
              }
              return moment(e.value).format("HH[h]");
            },
            style: {
              fontSize: "10px",
            },
          },
        },
        yAxis: [
          {
            min: 0,
            max: 10,
            labels: {
              format: "{value}",
            },
            title: {
              text: "Roadcond",
            },
            opposite: false,
          },
        ],
        // Labels Options
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          line: {
            dataLabels: {
              useHTML: true,
              enabled: false,
              // allowOverlap: true,
              verticalAlign: "top",
              align: "center",
              x: 0, // Offset des points
              y: -22,
              padding: 0,
              style: {
                fontSize: "12px",
              },
            },
            enableMouseTracking: true,
          },
        },
        chart: {
          animation: false,
        },
        time: {
          useUTC: false,
        },
        series,
        rangeSelector: {
          enabled: false,
        },
        navigator: {
          enabled: false,
        },
        scrollbar: {
          enabled: false,
        },
        legend: {
          align: "center",
          enabled: true,
          layout: "horizontal",
          verticalAlign: "top",
        },
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  align: "center",
                  verticalAlign: "bottom",
                  layout: "horizontal",
                },
                yAxis: {
                  labels: {
                    align: "left",
                    x: 0,
                    y: -5,
                  },
                  title: {
                    text: null,
                  },
                },
                subtitle: {
                  text: null,
                },
                credits: {
                  enabled: false,
                },
              },
            },
          ],
        },
      };
    },
    optionsGeojson() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    currentTimestamp() {
      return moment(this.selectedDate).format("YYYY-MM-DD HH:00:00");
    },
    findCursor() {
      return this.timestamps.findIndex((map) => map == this.cursor);
    },
  },
  data: () => ({
    paths: [],
    cursor: null,
    timestamps: [],
    selectedDate: new Date(),
    items: [],
    cameras: [],
    zoom: 5,
    mindt:null,
    maxdt:null,
    series: [],
    itemsGeocoder: [],
    polylines: [],
    moment: moment,
    webcamsLayer: false,
    itemsGeocodertwo: [],
    travel_conds: [],
    travel:null,
    hereKey: "QM2pGM2r7Ub5-WB_a5ENwalQzAKtPHinVD9nIdkhhB4",
    rvOpacity: 0,
    geocodersearch: null,
    geocodersearchtwo: null,
    from: null,
    to: null,
    roadcondTiles: false,
    roadsEvents: false,
    roadsLayer: false,
    markers: [],
    loading: false,
  }),
  methods: {
    format,
    onEachFeatureFunction(feature, layer) {
      console.log("feature", feature);
      let icon;
      let type = feature.properties.type;
      switch (type) {
        case "CONSTRUCTION":
          icon = "svg_icon/traffic-cone.svg";
          break;
        case "WEATHER":
          icon = "svg_icon/weather-pouring.svg";
          break;
        case "DISABLED_VEHICLE":
          icon = "svg_icon/car-off.svg";
          break;
        case "CONGESTION":
          icon = "svg_icon/car-multiple.svg";
          break;
        case "MISCELLANEOUS":
          icon = "svg_icon/alert.svg";
          break;
        default:
          icon = "svg_icon/alert.svg";
          break;
      }
      //console.log('marker',marker)
      let iconOptions = {
        iconUrl: icon,
        iconSize: [38, 95],
      };
      let newIcon = new L.Icon(iconOptions);
      layer.setIcon(newIcon);
      layer.bindPopup(
        `<div> <i>FIN le ${moment(feature.properties.end_date).format(
          "DD/MM/YYYY HH:mm"
        )}</i> </br> ${feature.properties.type} </div>`,
        {
          permanent: false,
          sticky: true,
        }
      );
    },
    updateZoom(e) {
      this.zoom = e;
      this.getCameras();
    },
    roadCondToTitle(e) {
      let text = null;
      switch (e) {
        case 1:
          text = "DRY";
          break;
        case 2:
          text = "WET";
          break;
        case 3:
          text = "ICE";
          break;
        case 4:
          text = "I/W";
          break;
        case 5:
          text = "DEW";
          break;
        case 6:
          text = "M/S";
          break;
        case 7:
          text = "FRO";
          break;
        case 8:
          text = "IR";
          break;
      }
      return text;
    },
    roadCondToColor(e) {
      let color = null;
      switch (e) {
        case 1:
          color = "0, 255, 55";
          break;
        case 2:
          color = "199, 255, 0";
          break;
        case 3:
          color = "242, 255, 0";
          break;
        case 4:
          color = "255, 225, 0";
          break;
        case 5:
          color = "250,145,255";
          break;
        case 6:
          color = "255, 140, 0";
          break;
        case 7:
          color = "255, 25, 0";
          break;
        case 8:
          color = "208, 18, 198";
          break;
      }
      return color;
    },
    async getRoadEvents() {
      this.loading = true;
      const { data } = await axios.get(
        `https://tileserver.previmeteo.net/cgi-bin/mapserv?map=/map/roads_color.map&SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&TYPENAME=incidents_route&MAXFEATURES=100000&outputFormat=geojson&layer=incidents_route&timestamp=${this.currentTimestamp}`
      );
      // this.roadsEvents = data;
      console.log("data", data);
      for (var i = 0; i < data.features.length; i++) {
        let lat = data.features[i].geometry.coordinates[1];
        let lng = data.features[i].geometry.coordinates[0];
        let iconUri;
        let type = data.features[i].properties.type;
        switch (type) {
          case "CONSTRUCTION":
            iconUri = "svg_icon/traffic-cone.svg";
            break;
          case "WEATHER":
            iconUri = "svg_icon/weather-pouring.svg";
            break;
          case "DISABLED_VEHICLE":
            iconUri = "svg_icon/car-off.svg";
            break;
          case "CONGESTION":
            iconUri = "svg_icon/car-multiple.svg";
            break;
          case "MISCELLANEOUS":
            iconUri = "svg_icon/alert.svg";
            break;
          default:
            iconUri = "svg_icon/alert.svg";
            break;
        }
        let icon = L.icon({
          iconUrl: iconUri,
          iconSize: [20, 18],
          iconAnchor: [10, 9],
        });
        this.markers.push(
          L.marker([lat, lng], { icon: icon }).bindPopup(
            `<div> <i>FIN le ${moment(
              data.features[i].properties.end_date
            ).format("DD/MM/YYYY HH:mm")}</i> </br> ${
              data.features[i].properties.type
            } </div>`
          )
        );
      }
      this.loading = false;
    },
    async getMinMaxDt()
    {
      const {data} = await axios.get(`https://roadengine.previmeteo.net/forecast/rangedt`);
      let {mindt, maxdt} = data;
      this.mindt = moment(mindt).format('YYYY-MM-DD[T]HH:mm');
      this.maxdt = moment(maxdt).format('YYYY-MM-DD[T]HH:mm');
      console.log(this.maxdt);
    },
    async navigateTo() {
      console.log("from", this.from);
      console.log("to", this.to);
      let from = this.from.geometry.coordinates;
      let to = this.to.geometry.coordinates;
      this.polylines = [];
      const here = await axios.get(
        `https://router.hereapi.com/v8/routes?transportMode=car&origin=${from[1]},${from[0]}&destination=${to[1]},${to[0]}&return=polyline&apiKey=${this.hereKey}`
      );
      this.travel = here.data;
      here.data.routes.forEach((route) => {
        route.sections.forEach((section) => {
          let lines = H.decode(section.polyline);
          this.polylines.push({
            lines: lines.polyline,
            arrival: section.arrival,
            color: "blue",
          });
        });
      });

      const roadcond = await axios.get(
        `https://roadengine.previmeteo.net/routing/${from[1]},${from[0]}/${
          to[1]
        },${to[0]}?departureTime=${moment(this.currentTimestamp).format(
          "YYYY-MM-DD[T]HH:MM:00"
        )}`
      );
      this.travel_conds = roadcond.data[0].travel_cond;
      this.setTable(roadcond.data);
    },
    setTable(data) {
      let rc = [];
      console.log("data is", data);
      data[0].travel_cond.forEach((element) => {
        rc.push({
          x: moment(element.time * 1000).unix() * 1000,
          y: element.roadcond,
        });
      });
      this.series.push({
        type: "bar",
        id: "rc",
        name: "Conditions de route",
        data: rc,
      });
    },
    initGeocoder() {
      let geocoder = L.Control.Geocoder.mapbox(
        "pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA"
      );
      L.Control.geocoder({ geocoder: geocoder, defaultMarkGeocode: false })
        .on("markgeocode", (e) => {
          this.navigateTo(e.geocode.center);
          // this.navigateTo({lat:44.8333,lng:-0.5667})
        })
        .addTo(this.$refs.map.mapObject);
    },
    geocoder(val) {
      console.log("val", val);
      this.navigateTo({ lat: val.center[1], lng: val.center[0] });
    },
    async getCameras() {
      let bounds = this.$refs.map.mapObject.getBounds();
      console.log(bounds);
      const { data } = await axios.get(
        `https://api.windy.com/api/webcams/v2/map/${bounds._northEast.lat},${bounds._northEast.lng},${bounds._southWest.lat},${bounds._southWest.lng},${this.zoom}?show=webcams:image,location,player&key=s9ifW3lDJvlSYMXgf7gaNIBDnkhGNdyJ`
      );
      let cameras = data;
      console.log("cameras,", cameras);
      this.cameras = cameras.result.webcams;
    },
    async getDatasets() {
      let items = await api.categories.get();
      items.map((map) => map.datasets.map((item) => (item.active = false)));
      items.map((map) => map.tilesets.map((item) => (item.active = false)));
      items.map((map) => map.wms.map((item) => (item.active = false)));
      this.items = items;
    },
    logout() {
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setJwt", null);
      localStorage.clear();
      this.$router.push("/login");
    },
    async getRainviewerPaths() {
      const { data } = await axios.get(
        "https://api.rainviewer.com/public/weather-maps.json"
      );
      data.radar.past.forEach((past) => {
        this.timestamps.push(past.time * 1000);
        this.paths.push(past.path);
      });
      data.radar.nowcast.forEach((now) => {
        this.timestamps.push(now.time * 1000);
        this.paths.push(now.path);
      });
      this.cursor = this.timestamps[this.timestamps.length - 3];
    },
  },
  mounted() {
    this.getMinMaxDt();
    this.getRainviewerPaths();
    this.getDatasets();
    this.getCameras();
    this.getRoadEvents();
    this.selectedDate = this.moment().format('YYYY-MM-DD[T]HH:00')
  },
  watch: {
    async geocodersearch(val) {
      console.log("search", val);
      if (val) {
        if (val.length > 0) {
          const items = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA`
          );
          this.itemsGeocoder = items.data.features;
        } else {
          this.itemsGeocoder = [];
        }
      }
    },
    async geocodersearchtwo(val) {
      console.log("search", val);
      if (val) {
        if (val.length > 0) {
          const items = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA`
          );
          this.itemsGeocodertwo = items.data.features;
        } else {
          this.itemsGeocodertwo = [];
        }
      }
    },
    async search(val) {
      console.log("search", val);
      const items = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${val}.json?access_token=pk.eyJ1IjoicG1jb20iLCJhIjoiY2lxajgxbHF3MDBjZWkwbWMza2pwYzFoayJ9.a1ywamqhc2BKAdxMdgcBpA`
      );
      this.items = items.data.features;
    },
  },
};
</script>

<style lang="scss">
:root {
  /* light mode */
  --background-color: #ffffff;
  --text-color: #292b2c;
  --hilight-color: #0275d8;
}

:root {
  --background-color: #1f2227;
  --text-color: #c0c0c0;
  --hilight-color: #8db4d6;
}

/* Some data colors. Light mode uses defaults */
.highcharts-color-0 {
  fill: #0460ba;
  stroke: #0460ba;
}
.highcharts-color-1 {
  fill: #9696ab;
  stroke: #9696ab;
}

body {
  background-color: var(--background-color);
}
.highcharts-background {
  fill: var(--background-color);
}
.highcharts-container text {
  fill: var(--text-color);
}
.highcharts-subtitle,
.highcharts-credits,
.highcharts-axis-title {
  fill-opacity: 0.7;
}
.highcharts-grid-line {
  stroke: var(--text-color);
  stroke-opacity: 0.2;
}
.highcharts-tooltip-box {
  fill: var(--background-color);
}
.highcharts-column-series rect.highcharts-point {
  stroke: var(--background-color);
}
</style>