<template>
  <ul
    class="short-color-scheme cs cs-1"
    id="color-scheme-map"
    onclick="minimizeLegend();"
  >
    <li>
      <div class="cs-clouds"></div>
      <p>Bruine (probable)</p>
    </li>
    <li>
      <div class="cs-percip-1"></div>
      <p>Bruine</p>
    </li>
    <li>
      <div class="cs-percip-2"></div>
      <p>Pluie légère</p>
    </li>
    <li>
      <div class="cs-percip-3"></div>
      <p>Pluie légère</p>
    </li>
    <li>
      <div class="cs-rainfall-1"></div>
      <p>Pluie</p>
    </li>
    <li>
      <div class="cs-rainfall-2"></div>
      <p>Pluie</p>
    </li>
    <li>
      <div class="cs-rainfall-3"></div>
      <p>Pluie forte</p>
    </li>
    <li>
      <div class="cs-storm-1"></div>
      <p>Pluie forte</p>
    </li>
    <li>
      <div class="cs-storm-2"></div>
      <p>Orage</p>
    </li>
    <li>
      <div class="cs-storm-3"></div>
      <p>Orage</p>
    </li>
    <li>
      <div class="cs-hail-1"></div>
      <p>Grêle</p>
    </li>
    <li>
      <div class="cs-hail-2"></div>
      <p>Grêle</p>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      mode: "rain",
    };
  },
};
</script>

<style lang="scss">
.cs-0 .cs {
  &-clouds {
    background-color: #dfdfdf;
  }
  &-percip {
    &-1 {
      background-color: #9bea8f;
    }
    &-2 {
      background-color: #58ff42;
    }
    &-3 {
      background-color: #47c278;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #4793f9;
    }
    &-2 {
      background-color: #0c59ff;
    }
    &-3 {
      background-color: #6153c1;
    }
  }
  &-storm {
    &-1 {
      background-color: #ff93a3;
    }
    &-2 {
      background-color: #ff3f35;
    }
    &-3 {
      background-color: #c20511;
    }
  }
  &-hail {
    &-1 {
      background-color: #ffeb0a;
    }
    &-2 {
      background-color: #ff9811;
    }
    &-3 {
      background-color: #a84c06;
    }
  }
}

.cs-1 .cs {
  &-clouds {
    background-color: #88eeee;
  }
  &-percip {
    &-1 {
      background-color: #0099cc;
    }
    &-2 {
      background-color: #0077aa;
    }
    &-3 {
      background-color: #005588;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #ffee00;
    }
    &-2 {
      background-color: #ffaa00;
    }
    &-3 {
      background-color: #ff7700;
    }
  }
  &-storm {
    &-1 {
      background-color: #ff4400;
    }
    &-2 {
      background-color: #ee0000;
    }
    &-3 {
      background-color: #990000;
    }
  }
  &-hail {
    &-1 {
      background-color: #ffaaff;
    }
    &-2 {
      background-color: #ff77ff;
    }
    &-3 {
      background-color: #ff44ff;
    }
  }
}

.cs-2 .cs {
  &-clouds {
    background-color: #1c47e8;
  }
  &-percip {
    &-1 {
      background-color: #087fdb;
    }
    &-2 {
      background-color: #6e0dc6;
    }
    &-3 {
      background-color: #c80f86;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #c06487;
    }
    &-2 {
      background-color: #d2883b;
    }
    &-3 {
      background-color: #fac431;
    }
  }
  &-storm {
    &-1 {
      background-color: #fefa03;
    }
    &-2 {
      background-color: #fe9a58;
    }
    &-3 {
      background-color: #fe5f05;
    }
  }
  &-hail {
    &-1 {
      background-color: #fd341c;
    }
    &-2 {
      background-color: #bebebe;
    }
    &-3 {
      background-color: #d3d3d3;
    }
  }
}

.cs-3 .cs {
  &-clouds {
    background-color: #01b714;
  }
  &-percip {
    &-1 {
      background-color: #088915;
    }
    &-2 {
      background-color: #11651a;
    }
    &-3 {
      background-color: #064307;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #ffee07;
    }
    &-2 {
      background-color: #f8bb08;
    }
    &-3 {
      background-color: #f38b08;
    }
  }
  &-storm {
    &-1 {
      background-color: #f07108;
    }
    &-2 {
      background-color: #ea5e09;
    }
    &-3 {
      background-color: #df370a;
    }
  }
  &-hail {
    &-1 {
      background-color: #d3100c;
    }
    &-2 {
      background-color: #c00d09;
    }
    &-3 {
      background-color: #b80c08;
    }
  }
}

.cs-5 .cs {
  &-clouds {
    background-color: #00efe7;
  }
  &-percip {
    &-1 {
      background-color: #009cf7;
    }
    &-2 {
      background-color: #0000f7;
    }
    &-3 {
      background-color: #00ff00;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #03b703;
    }
    &-2 {
      background-color: #087305;
    }
    &-3 {
      background-color: #ffff00;
    }
  }
  &-storm {
    &-1 {
      background-color: #ecce00;
    }
    &-2 {
      background-color: #fe9300;
    }
    &-3 {
      background-color: #ff0000;
    }
  }
  &-hail {
    &-1 {
      background-color: #bd0000;
    }
    &-2 {
      background-color: #bd0000;
    }
    &-3 {
      background-color: #fe00fe;
    }
  }
}

.cs-6 .cs {
  &-clouds {
    background-color: #009f9f;
  }
  &-percip {
    &-1 {
      background-color: #008c4b;
    }
    &-2 {
      background-color: #00d319;
    }
    &-3 {
      background-color: #21fd22;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #fffd1b;
    }
    &-2 {
      background-color: #ffd400;
    }
    &-3 {
      background-color: #ffab00;
    }
  }
  &-storm {
    &-1 {
      background-color: #ff6e00;
    }
    &-2 {
      background-color: #f01002;
    }
    &-3 {
      background-color: #d00523;
    }
  }
  &-hail {
    &-1 {
      background-color: #e400b1;
    }
    &-2 {
      background-color: #ff00ff;
    }
    &-3 {
      background-color: #ff00ff;
    }
  }
}

.cs-7 .cs {
  &-clouds {
    background-color: #005eb6;
  }
  &-percip {
    &-1 {
      background-color: #005eb6;
    }
    &-2 {
      background-color: #005eb6;
    }
    &-3 {
      background-color: #2458af;
    }
  }
  &-rainfall {
    &-1 {
      background-color: #8e4b9b;
    }
    &-2 {
      background-color: #fc5370;
    }
    &-3 {
      background-color: #ffb76e;
    }
  }
  &-storm {
    &-1 {
      background-color: #fffd05;
    }
    &-2 {
      background-color: #fffd05;
    }
    &-3 {
      background-color: #fffd05;
    }
  }
  &-hail {
    &-1 {
      background-color: #fffd05;
    }
    &-2 {
      background-color: #fffd05;
    }
    &-3 {
      background-color: #fffd05;
    }
  }
}


.short-color-scheme {
  margin: 0;
  list-style: none;

  li {
    position: relative;
    display: block;
    height: 16px;
    text-align: left;
  }

  div {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  p {
    display: inline-block;
    font-size: 12px;
    padding-left: 4px;
    height: 16px;
  }
}

</style>
