import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    jwt: localStorage.getItem('jwt') || null
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    SET_JWT(state, jwt) {
      state.jwt = jwt;
      localStorage.setItem('jwt', jwt);
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user);
    },
    setJwt({ commit }, jwt) {
      commit('SET_JWT', jwt);
    }
  },
  modules: {
  }
})
