<template>
  <ol style="list-style-type:none">
    <li>
        <div style="background-color:rgb(0, 255, 55);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Pavé sec</p>
    </li>
    <li>
        <div style="background-color:rgb(250,145,255);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Rosée</p>
    </li>
    <li>
        <div style="background-color:rgb(199, 255, 0);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Pavé mouillé</p>
    </li>
    <li>
        <div style="background-color:rgb(242, 255, 0);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Glace/neige sur la route</p>
    </li>
    <li>
        <div style="background-color:rgb(255, 225, 0);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Mélange glace/eau sur la route</p>
    </li>
    <li>
        <div style="background-color:rgb(255, 140, 0);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Neige fondante</p>
    </li>
    <li>
        <div style="background-color:rgb(255, 25, 0);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Gel (glace noire)</p>
    </li>
    <li>
        <div style="background-color:rgb(208, 18, 198);height:10px;width:10px;display:inline-block"></div>
        <p style="display:inline-block">Pluie verglaçante</p>
    </li>
</ol>
</template>

<script>
export default {

}
</script>

<style>

</style>